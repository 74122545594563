import React from "react"
import SEO from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import Callout from "../components/callout";
import ArticleTile from "../components/article-tile";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link, graphql } from "gatsby"

export default class Article extends React.Component {
    render() {
        let global = this.props.data.contentfulWebsite;
        return (
            <Layout pathname={this.props.location.pathname}>
                <SEO metaTitle={`${this.props.pageContext.article.title} - ${global.title}`} metaDescription={`${this.props.pageContext.article.excerpt} - ${global.description}`} author={global.author} shareImage={this.props.pageContext.article.featuredImage ? this.props.pageContext.article.featuredImage.resize.src : global.shareImage.file.url} />
                <div className="o-wrapper o-wrapper--small o-content o-container">
                    <div className="o-child-spacer o-child-spacer--small">
                        {this.props.pageContext.blogs.map((blog, index) => {
                            return (
                                <Link key={index} to={`/blog/${blog.slug}`} className={`c-tab ${this.props.pageContext.article.blogCategory.slug === blog.slug ? 'c-tab--active' : ''}`}>{blog.name}</Link>
                            )
                        })}
                    </div>
                    <div className="o-content-spacer o-content-container">
                        <h1 className="u-text-center">{this.props.pageContext.article.title}</h1>
                        <div>
                            <div className="u-text-large">Written by: {this.props.pageContext.article.author}</div>
                            <div>{this.props.pageContext.article.publishDate}</div>
                        </div>
                        {this.props.pageContext.article.featuredImage ? (
                            <div className="o-wrapper o-wrapper--xsmall">
                                <img alt={this.props.pageContext.article.title} className="c-article-image u-img-border" src={this.props.pageContext.article.featuredImage.resize.src} />
                            </div>
                        ) : ''}
                        <div className="o-content-container">
                            {documentToReactComponents(JSON.parse(this.props.pageContext.article.content.raw))}
                        </div>
                    </div>
                    {this.props.pageContext.related_articles.length > 0 ? (
                        <div className="o-content-container o-content-container--large o-content-spacer o-content-spacer--large u-text-center">
                            <div className="h2">{global.relatedArticlesTitle}</div>
                            <div className="o-flex o-flex--third o-flex--align-start o-content-spacer o-content-spacer--small">
                                {this.props.pageContext.related_articles.map((article, index) => {
                                    return <ArticleTile key={index} article={article} readMore={global.articleCtaText} />
                                })}
                            </div>
                        </div>
                    ) : ''}
                </div>
                <div className="o-content-container">
                    {this.props.pageContext.article.cards.map((callout, index) => <Callout key={index} callout={callout} />)}
                </div>
            </Layout>
        );
    };
}

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Kneading Dough Canada"}) {
        author
        description
        title
        articleCtaText
        relatedArticlesTitle
        shareImage {
            file {
                url
            }
        }
    }
}
`;
